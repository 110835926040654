import { useEffect, useState } from "react";
import { numberWithCommas } from "../../../api/service";
import { usePopup } from "../../../context";
import { EventName, postEvent } from "../../../dropin/events";
import { useCalculatedAmount } from "../../../hooks";
import { BankContainer, BankText } from "../../bank";
import { AppButton } from "../../components/app-button";
import { Spacer } from "../../components/spacer";
import SuccessImage from "../../../assets/success_icon.svg"
import { Image } from "../../nav";
import useMediaQuery from "../../components/use-mediaquery";

export const SuccessfulTransaction = () => {
  const {
    merchantDetails: { account_name },
    isSuccess,
    position,
    paymentPayload,
    options,
  } = usePopup();


  const matches = useMediaQuery("(max-width: 600px)");

  const { channels = ["bank", "ussd", "card"], isWoocommerce = false } =
    options;
  const waitTime = isWoocommerce ? 3 : 20;
  const [time, setTime] = useState<number>(waitTime);

  const { AmountToShow } = useCalculatedAmount();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
    setTimeout(() => {
      postEvent({
        target: window.parent,
        event: {
          name: EventName.RETURN,
          payload: {
            message: `Transaction successful. Amount: ${AmountToShow}`,
            code: "00",
            payment_payload: paymentPayload,
          },
        },
      });
    }, 20000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    if (isSuccess) {
      postEvent({
        target: window.parent,
        event: {
          name: EventName.RETURN,
          payload: {
            message: `Transaction successful. Amoount: ${AmountToShow}`,
            code: "00",
            payment_payload: paymentPayload,
          },
        },
      });
      postEvent({
        target: window.parent,
        event: {
          name: EventName.EXIT,
          payload: {
            message: `Transaction successful. Amoount: ${AmountToShow}`,
            code: "00",
            payment_payload: paymentPayload,
          },
        },
      });
    }
  };

  useEffect(() => {
    // if (time === 0) {
    //   onClose();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const _Text1 = (
    <>
      ₦<strong>{numberWithCommas(AmountToShow)}</strong> payment to{" "}
      <strong>{account_name}</strong> has been confirmed.
    </>
  );
  const _Text2 = (
    <>
       ₦<strong>{numberWithCommas(AmountToShow)}</strong> payment has been
      confirmed.
    </>
  );

  return (
    <BankContainer>
      {/* <SuccessAnimationCheckmark /> */}
      <Spacer height={40} />
      <Image src={SuccessImage} />
      <Spacer height={20} />
      <span style={{color:"#282829", fontSize:"18px", fontWeight:'500'}}>Transaction Successful</span>
      <Spacer height={15} />
      <BankText style={{color:"#8E8E93"}}>
        {channels[position as number] === "card" ? _Text2 : _Text1}
      </BankText>
      <Spacer height={36} />
      <AppButton
        type="grayBtn"
        disabled={false}
        onClick={() => onClose()}
        isBusy={false}
        name={`${matches? "Back To Merchant":"Done"}`}
      />
      {/* <UnstyledButton onClick={() => onClose()}>Redirecting in {time} seconds</UnstyledButton> */}
    </BankContainer>
  );
};
